import { api } from "./api.service";

let config = null;

export const getAll = () => {
  if (config) return Promise.resolve(config);

  return api.get('login/config').then(response => {
    config = response.data;
    return config;
  });
};

export const getPoliticaPrivacidade = () => {

  return api.get('login/politica-privacidade');
};

export const getTermosUso = () => {
  return api.get('login/termo-uso');
};

export default { getAll, getPoliticaPrivacidade, getTermosUso };
