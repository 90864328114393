var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "home-btn d-none d-sm-block" },
      [
        _c("router-link", { staticClass: "text-dark", attrs: { to: "/" } }, [
          _c("i", { staticClass: "mdi mdi-home-variant h2" })
        ])
      ],
      1
    ),
    _c("div", { staticClass: "account-pages my-5 pt-sm-5" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-md-8 col-lg-6 col-xl-5" }, [
            _c(
              "div",
              [
                _c(
                  "router-link",
                  { staticClass: "mb-5 d-block auth-logo", attrs: { to: "/" } },
                  [
                    _c("img", {
                      staticClass: "logo logo-dark",
                      attrs: {
                        src: require("@/assets/images/logo-dark.png"),
                        alt: "",
                        width: "180"
                      }
                    }),
                    _c("img", {
                      staticClass: "logo logo-light",
                      attrs: {
                        src: require("@/assets/images/logo-light.png"),
                        alt: "",
                        width: "180"
                      }
                    })
                  ]
                ),
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body p-4" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "p-2 mt-4" }, [
                      _c(
                        "div",
                        {
                          staticClass: "text-muted mb-4",
                          attrs: { role: "alert" }
                        },
                        [
                          _vm._v(
                            " Preencha os dados abaixo para atualizar a sua senha. "
                          )
                        ]
                      ),
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.recuperar($event)
                            }
                          }
                        },
                        [
                          _c("f-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: true, email: true },
                                expression: "{required: true, email: true}"
                              }
                            ],
                            attrs: {
                              name: "codigo",
                              type: "email",
                              "data-vv-as": "E-mail",
                              label: "Email",
                              modelErrors: _vm.errors,
                              cols: 12
                            },
                            model: {
                              value: _vm.model.email,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "email", $$v)
                              },
                              expression: "model.email"
                            }
                          }),
                          _c("f-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: true },
                                expression: "{ required: true }"
                              }
                            ],
                            ref: "novaSenha",
                            attrs: {
                              name: "novaSenha",
                              type: "password",
                              "data-vv-as": "Nova Senha",
                              label: "Sua nova senha",
                              modelErrors: _vm.errors,
                              cols: 12
                            },
                            model: {
                              value: _vm.model.novaSenha,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "novaSenha", $$v)
                              },
                              expression: "model.novaSenha"
                            }
                          }),
                          _c("f-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: true },
                                expression: "{ required: true }"
                              }
                            ],
                            attrs: {
                              name: "confirmacaoSenha",
                              type: "password",
                              "data-vv-as": "novaSenha",
                              label: "Confirme sua senha",
                              modelErrors: _vm.errors,
                              cols: 12
                            },
                            model: {
                              value: _vm.model.confirmacaoSenha,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "confirmacaoSenha", $$v)
                              },
                              expression: "model.confirmacaoSenha"
                            }
                          }),
                          _vm._m(1)
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "mt-5 text-center" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.appConfig.devYear) +
                        " ® " +
                        _vm._s(_vm.appConfig.title) +
                        " " +
                        _vm._s(
                          _vm.devConfig
                            ? " - Versão " + _vm.devConfig.versao
                            : ""
                        )
                    )
                  ]),
                  _vm.devConfig
                    ? _c("p", [
                        _vm._v("Desenvolvido por "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.devConfig.devUrl,
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(_vm.devConfig.devNome))]
                        )
                      ])
                    : _vm._e()
                ])
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center mt-2" }, [
      _c("h5", { staticClass: "text-primary" }, [_vm._v("Atualizar senha")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-0" }, [
      _c("div", { staticClass: "col-12 text-end" }, [
        _c(
          "button",
          { staticClass: "btn btn-primary w-sm", attrs: { type: "submit" } },
          [_vm._v(" Salvar nova senha ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }